import './styles/theme.scss';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { store } from './redux/store';

import App from "./App";
import { I18nextProvider } from 'react-i18next';
import { i18n } from './tools';
import { Scrollbar } from 'react-scrollbars-custom';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { SCREEN_MODE } from "./constants";

const screenMode = localStorage.getItem(SCREEN_MODE);
    
// Or Create your Own theme:
const customtheme = createTheme({
  palette: {
    primary: {
      main: '#009c49'
    },
    secondary: {
      main: '#f44336'
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
        minWidth: 150
      }, 
    }, 
  },   
});

const Root = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Scrollbar style={{ width: '100hw', height: '100vh' }}>
        <ThemeProvider theme={customtheme}>
          <App />
        </ThemeProvider>
      </Scrollbar>
    </I18nextProvider>
  </Provider>
);

const dom = document.getElementById('root');

if (screenMode === 'dark') {
  dom.classList.add("dark");
} else {
  dom.classList.remove("dark");
}
ReactDOM.render(<Root />, dom);
