import { commonService } from '.';
var appendQuery = require('append-query');

//
function getCooptations(page, size, sort, search) {
    if (!search) search = {};
    search.pageNumber = page;
    search.pageSize = size;
    search.sortField = sort.sortField;
    search.sortDirection = sort.sortDirection;

    let query = appendQuery('cooptations/all', search)
    return commonService.get(query);
}

function getCooptation(id) {
    return commonService.get(`cooptations/${id}`);
}

function getAllStates(id) {
    return commonService.get('states');
}

function createStep(id, data) {
    return commonService.post(`${id}/timeline`, data);
}

function deleteStep(cooptId, hisId) {
    return commonService.del(`${cooptId}/timeline/${hisId}`);
}

function deleteCooptation(id) {
    return commonService.del(`cooptations/${id}`);
}

function getDuplications(page, size, sort, search) {
    if (!search) search = {};
    search.pageNumber = page;
    search.pageSize = size;
    search.sortField = sort.sortField;
    search.sortDirection = sort.sortDirection;

    let query = appendQuery('duplications/all', search)
    return commonService.get(query);
}

function getDuplication(canReference) {
    let query = appendQuery(`duplications/${canReference}`)
    return commonService.get(query);
}

function getCooptationOwner(canReference) {
    let query = appendQuery(`main-cooptation/${canReference}`)
    return commonService.get(query);
}

function changeCooptationOwner(canId, data) {
    return commonService.post(`${canId}/change-owner`, data);
}

function unrejectMainOwner(canId, data) {
    return commonService.post(`${canId}/unreject-owner`, data);
}

//
export const adminService = {
    getCooptations,
    getAllStates,
    getCooptation,
    createStep,
    deleteStep,
    deleteCooptation,
    getDuplications,
    getDuplication,
    getCooptationOwner,
    changeCooptationOwner,
    unrejectMainOwner
};
