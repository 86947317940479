import { adminActionsType } from '../../constants';

export function administrations (state = {}, action) {

  switch (action.type) {
    case adminActionsType.LIST_COOPTATIONS:
      return {
        ...state,
        cooptations: action.cooptations
      };
    
    case adminActionsType.COOPTATION:
      return {
        ...state,
        cooptation: action.cooptation
      };
    
    case adminActionsType.LIST_STATES:
      return {
        ...state,
        states: action.states
      };   
    
    case adminActionsType.DELETED_STEP:
      return {
        ...state,
        deleted: action.deleted
      };   
    
    case adminActionsType.CREATED_STEP:
      return {
        ...state,
        created: action.created
      };   
    
    case adminActionsType.DELETED_COOPTATION:
      return {
        ...state,
        deleted: action.deleted
      };    
    
    case adminActionsType.LIST_DUPLICATIONS:
      return {
        ...state,
        duplications: action.duplications
      };
    
    case adminActionsType.GET_DUPLICATION:
      return {
        ...state,
        duplication: action.duplication
      };
    
    case adminActionsType.OWNER_CHANGED:
      return {
        ...state,
        ownerChanged: action.ownerChanged
      };    
    
    case adminActionsType.OWNER_UNREJECTED:
      return {
        ...state,
        ownerUnrejected: action.ownerUnrejected
      };
    
    default:
      return state
  }
}

