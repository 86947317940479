import { loaderActionsType } from '../../constants';

const initialState = { pending: false };

export function loading(state = initialState, action) {
  switch (action.type) {
    case loaderActionsType.PENDINGMINI:
      return {
        pendingMini: true
      };    
    case loaderActionsType.PENDING:
      return {
        pending: true,
        message: action.message
      };
    case loaderActionsType.END:
      return {
        pendingMini: false,
        pending: false
      };
    default:
      return state
  }
}
