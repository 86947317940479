import { commonService } from './';
var appendQuery = require('append-query');
//
function createCandidate(formData) {
    return commonService.post('Candidates', formData);
}

function readCV(formData) {
    return commonService.post('Candidates/hireability', formData);
}

function getCandidates(number, size) {
    let query = appendQuery('Candidates', { pageNumber: number, pageSize: size })
    return commonService.get(query);
}

function getTimeline(id) {
    return commonService.get(`Timelines/${id}`);
}

function getAnnoncesCoopt() {
    return commonService.get(`Candidates/annonces`);
}

function sendMail(formData) {
    return commonService.post('Candidates/sendMail', formData);
}

//
export const candidateService = {
    createCandidate,
    getCandidates,
    getTimeline,
    readCV,
    getAnnoncesCoopt,
    sendMail
};
