import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as CloseMenu } from "../../assets/x.svg";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { authActions } from "../../redux/actions";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { store } from '../../redux/store';
import { authProvider } from '../../services';
import { adminActionsType, CURRENT_USER, SCREEN_MODE } from '../../constants';
import $ from "jquery";

import Tooltip from '@material-ui/core/Tooltip';

import ReactDOMServer from 'react-dom/server';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import './../../styles/opportunity.scss'; 

const options = [
  {
    label: "manage_cooptations",
    link: "/adm-cooptations",
    role: "ADM"
  },
  {
    label: "manage_duplications",
    link: "/adm-duplications",
    role: "ADM"
  }
];

const Header = (param) => {
  const history = useHistory();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const [culture, isFrench] = useState(localStorage.getItem('lan') === 'en' || false);
  const user = JSON.parse(localStorage.getItem(CURRENT_USER));
  const firstname = user.firstName || "";
  const lastname = user.lastName || "";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(localStorage.getItem(adminActionsType.ADM_MENU_SELECTED) || 0);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option, index) => {     
    localStorage.setItem(adminActionsType.ADM_MENU_SELECTED, index);
    setSelectedIndex(index);

    setAnchorEl(null);

    if(option.link) history.push(option.link);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const changeCulture = (x) => {
    isFrench(x);
    const lan = x ? 'en' : 'fr'
    localStorage.setItem('lan', lan);
    param.t.changeLanguage(lan);

    closeMobileMenu();
  }

  const dispatch = useDispatch();

  //Menu active on click
  // $(function ($) {
  //   let url = window.location.href;
  //   let urlend = url.split("/")[url.split("/").length - 1];
  //   $('.option a').each(function () {
  //     if (this.id === urlend) {
  //       $(this).addClass('active');
  //     }
  //     else {
  //       $(this).removeClass('active');
  //     }
  //   });
  // });

  //
  const toggleMode = () => {
      if ($("#root").hasClass('dark')) {
          $("#root").removeClass('dark');
          localStorage.setItem(SCREEN_MODE, 'light');
      } else {
          $("#root").addClass('dark');
          localStorage.setItem(SCREEN_MODE, 'dark')
      }
      setAnchorEl(null);
  }

  return (
    <div className={click ? "header onfocus" : "header"} >
      <div className="logo-nav">
        <div className="logo-container">
          <a className="link" href={undefined} onClick={() => history.push("/")}>
            <img src={require("../../assets/img/logo-coopt-black.png")} className="logo" alt="" />
          </a>
          <ul className="nav-user">
            <li className="pipe"></li>
            <li><span>{firstname} {lastname}</span></li>
          </ul>
        </div>
      </div>
      <div className="app-title">
        <ul className="nav-user">
          <li className="pipe"></li>
          <li><span>{firstname} {lastname}</span></li>
        </ul>
        {/* <span className="header-sitename" onClick={() => history.push("/")}><Trans>sitenamef</Trans></span> */}
      </div>
      <ul className={click ? "p-lg-0 nav-options active" : "p-lg-0 nav-options"} style={{justifyContent: 'flex-end', alignItems: 'center', display: 'flex' }}>
        {click ? (
          <li className="option">
            <a href={undefined} className="link" onClick={() => history.push("/")}><Trans>home</Trans></a>
          </li>
        ) : ""}
        <li className="option pl-lg-2" onClick={closeMobileMenu}>
          <a href={undefined} className={`link ${window.location.href.indexOf('/apply') > -1?'active':''}`} id="cooptation" onClick={() => history.push("/apply")}><Trans>newcooptation</Trans></a>
        </li>
        <li className="option pl-lg-2" onClick={closeMobileMenu}>
          <a href={undefined} className={`link ${window.location.href.indexOf('/opportunity') > -1?'active':''}`} id="cooptationDiscover" onClick={() => history.push("/opportunity")}><Trans>opportunitiesDiscover</Trans></a>
        </li>
        <li className="option pl-lg-2" onClick={closeMobileMenu}>
          <a href={undefined} className={`link ${window.location.href.indexOf('/my-cooptations') > -1?'active':''}`} id="cooptations" onClick={() => history.push("/my-cooptations")}><Trans>mycooptations</Trans></a>
        </li>

        <li className="option popover__wrapper_flag pl-lg-2" style={{display: 'inline-flex'}}>
          <Tooltip title={ReactDOMServer.renderToStaticMarkup(culture ? <Trans>headerswitchfrench</Trans> : <Trans>headerswitchenglish</Trans>)} arrow>
            <a href={undefined} className="link pl-md-3 pl-lg-4 pr-lg-4" onClick={() => changeCulture(!culture)}>
              <img className="popover__title_flag ignore-dark" width="25" src={require("../../assets/flags/" + (culture ? "fr.svg" : "en.svg"))} alt="" />
              {/* <div className="popover__content_flag hidden_mobile">
                <p className="popover__message_flag">{(culture ? <Trans>headerswitchfrench</Trans> : <Trans>headerswitchenglish</Trans>)}</p>
              </div> */}
            </a>
          </Tooltip>

          <Tooltip title={ReactDOMServer.renderToStaticMarkup(<Trans>settings</Trans>)} arrow>
            <a className="link pl-md-3 pl-lg-3 pr-lg-3" href={undefined} onClick={handleClickListItem}>
              <img className="" width="20" src={require("../../assets/img/cogwheel-outline.png")} alt="" />
            </a> 
          </Tooltip>

          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{marginTop: 50, marginLeft: -24}}
          >          
            { user?.profile === "ADM" && 
              options.map((option, index) => (
              <MenuItem
                key={option.label}
                //disabled={index == selectedIndex}
                selected={index == selectedIndex}
                onClick={() => handleMenuItemClick(option, index)}
                style={{ margin: 8 }}
              >
                <Trans>{option.label}</Trans>
              </MenuItem>
            ))}
            <MenuItem
              key="screen_mode"
              onClick={() => toggleMode()}
              style={{ margin: 8 }}
            >
              <Trans>{ 'screen_mode_from-' + (localStorage.getItem(SCREEN_MODE) != "null"?localStorage.getItem(SCREEN_MODE):'light')}</Trans>
            </MenuItem>              
          </Menu>

          <span className="pl-md-3 pl-lg-3 pr-lg-3" onClick={closeMobileMenu}>
            <Tooltip title={ReactDOMServer.renderToStaticMarkup(<Trans>headerlogout</Trans>)} arrow>
              <AzureAD provider={authProvider} reduxStore={store}>
                {({ logout, authenticationState }) => {
                  const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
                  const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;
                  if (isAuthenticated) {
                    return (
                      <React.Fragment>
                        <a href={undefined} onClick={logout} className="popover__title_logout link">
                          {click ? (
                            <b className="mobile-deconnection"><Trans>headerlogoutmobile</Trans></b>
                          ) : (
                            <img width="20" src={require("../../assets/img/Icon feather-log-out.svg")} alt="" />
                          )}

                        </a>
                      </React.Fragment>
                    );
                  } else if(isUnauthenticated) {
                    return (
                      <React.Fragment>
                        <a href={undefined} onClick={() => dispatch(authActions.logout())} className="popover__title_logout link">
                          {click ? (
                            <b className="mobile-deconnection"><Trans>headerlogoutmobile</Trans></b>
                          ) : (
                            <img width="20" src={require("../../assets/img/Icon feather-log-out.svg")} alt="" />
                          )}

                        </a>
                      </React.Fragment>
                    );
                  }
                }}
              </AzureAD>
            </Tooltip>
          </span>
          {/* <div className="popover__content_logout hidden_mobile">
            <p className="popover__message_logout"><Trans>headerlogout</Trans></p>
          </div> */}
        </li>
      </ul>
      <div className={click ? "mobile-menu opened" : "mobile-menu"} onClick={handleClick}>
        {click ? (
          <CloseMenu className="menu-icon" />
        ) : (
          <MenuIcon className="menu-icon" />
        )}
      </div>
    </div>
  );
};

export default Header;
