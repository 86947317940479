import { candidateService } from '../../services';
import { candidateActionsType } from '../../constants';
//import { history } from "../../tools";
import { alertActions } from '../actions';

// Create of candidates
const readCV = (formData) => {
    return dispatch => {
        return candidateService.readCV(formData)
        .then(
            cv => { 
                return dispatch(success(cv));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };    
    function success(cv) {
        return { type: candidateActionsType.READ_CV, cv }
    }
}

// Create of candidates
const createCandidate = (formData) => {
    return dispatch => {
        return candidateService.createCandidate(formData)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };    
    function success(candidate) {
        return { type: candidateActionsType.CREATE_CANDIDATE, candidate }
    }
}

// List of candidates
const getCandidates = (number, size) => {
    return dispatch => {
        return candidateService.getCandidates(number, size)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(candidates) {
        return { type: candidateActionsType.LIST_CANDIDATES, candidates } }
}

// List timeline
const getTimeline = (idCandidat) => {
    return dispatch => {
        return candidateService.getTimeline(idCandidat)
            .then(
                response => { 
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
            };
    function success(timeline) {
        return { type: candidateActionsType.LIST_TIMELINE, timeline } }
}

// Get Annonces
const getAnnonces = () => {
    return dispatch => {
        return candidateService.getAnnoncesCoopt()
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(states) {
        return { type: candidateActionsType.LIST_ANNONCES, states } }
}

//Envoi mail
const sendMail = (formData) => {
    return dispatch => {
        return candidateService.sendMail(formData)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };    
    function success(candidate) {
        return { type: candidateActionsType.CREATE_CANDIDATE, candidate }
    }
}

export const candidateActions = {
    createCandidate,
    getCandidates,
    getTimeline,
    readCV,
    getAnnonces,
    sendMail
};