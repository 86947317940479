import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import { alertActions } from './redux/actions';
import { CURRENT_USER, SCREEN_MODE } from './constants';
import Header from "./views/header/Header";
import Footer from "./views/footer/Footer";
import TopAlert from './views/components/top.alert';
import Loader from './views/components/loader.spinner';
import BlockUi from 'react-block-ui';
import { jwt } from './tools';
import './styles/block-ui.scss';
import AlertDialog from "./views/components/alert.dialog";
import ScrollToTop from "./views/components/scrollToTop";

// Pages
//const Routes= React.lazy(() => import('./views/routers/Routes'));

const LegalNotice = React.lazy(() => import('./views/containers/legalnotice'));
const Page404 = React.lazy(() => import('./views/containers/404'));
const ErrorPage = React.lazy(() => import('./views/containers/500'));
const RecoverPassword = React.lazy(() => import('./views/containers/password'));
const Login = React.lazy(() => import('./views/containers/login'));
const Cooptation = React.lazy(() => import('./views/containers/cooptation'));
const Cooptations = React.lazy(() => import('./views/containers/cooptations'));
const Cooptationsentnotification = React.lazy(() => import('./views/containers/cooptationsentnotification'));
const Home = React.lazy(() => import('./views/containers/home'));
const Cooptationfollowup = React.lazy(() => import('./views/containers/cooptationfollowup'));
const ListCooptations = React.lazy(() => import('./views/containers/admin/listCooptations'));
const DetailCooptation = React.lazy(() => import('./views/containers/admin/detailCooptation'));
const ListDuplications = React.lazy(() => import('./views/containers/admin/listDuplications'));
const DetailDuplication = React.lazy(() => import('./views/containers/admin/detailDuplication'));
const Opportunity = React.lazy(() => import('./views/containers/opportunities'));
const DetailOpportunity = React.lazy(() => import('./views/containers/detailOpportunity'));

class App extends Component {  

  render() {
    const { loading, alert } = this.props;
    const isTokenExpired = jwt.isExpired();
    const user = JSON.parse(localStorage.getItem(CURRENT_USER));

    return (
      <div className="App">
        <HashRouter>
          <ScrollToTop>
            <React.Suspense fallback={<Loader/>}>
            {loading.pending &&
              <Loader message={loading.message}/>
            }
            <BlockUi tag="div" className="app-content" blocking={loading.pending}>
              {alert.message && (isTokenExpired || user?.profile === "ADM") &&
                <>
                  {alert.message === "ERROR_NOT_BETA_TESTER" ?
                    <AlertDialog open={true} message={alert.message} />
                  :
                    <TopAlert/>
                  }
                </>
              }
              {alert.message && !isTokenExpired && user?.profile !== "ADM" &&
                <Redirect to="/error" />
              }              
              <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} /> 
                  {isTokenExpired &&             
                    <Redirect to="/login" />              
                  }
                 
                  <Route exact path="/error" name="Error page" render={props => <><Header t = {this.props.i18n}/><ErrorPage {...props}/><Footer/></>} />
                  <Route exact path="/legalnotice/:id" name="Legal Notice"><LegalNotice t = {this.props.i18n} /></Route>
                  
                  <Route exact path="/logout" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/register" name="Recover password" render={props => <RecoverPassword {...props}/>} />
                     
                  <Route exact path="/" name="Home Page" render={props => <> <Header t = {this.props.i18n}/> <Home {...props}/><Footer/></>} /> 
                  <Route exact path="/my-cooptations" name="Cooptations page" render={props =>  <><Header t = {this.props.i18n}/><React.Suspense fallback={<Loader/>}><Cooptations {...props}/><Footer/></React.Suspense></>} />
                  <Route exact path="/apply" name="One Cooptation Page" render={props =>  <><Header t = {this.props.i18n}/><React.Suspense fallback={<Loader/>}><Cooptation {...props}/><Footer/></React.Suspense></>} />
                  <Route exact path="/opportunity" name="Opportunity Discover" render={props =>  <><Header t = {this.props.i18n}/><React.Suspense fallback={<Loader/>}><Opportunity {...props}/><Footer/></React.Suspense></>} />
                  <Route exact path="/detailOpportunity/:id" name="Opportunity Details" render={props =>  <><Header t = {this.props.i18n}/><React.Suspense fallback={<Loader/>}><DetailOpportunity {...props}/><Footer/></React.Suspense></>} />
                  <Route exact path="/cooptationsentnotification" name="One Cooptation Sent Page" render={props =>  <><Header t = {this.props.i18n}/><React.Suspense fallback={<Loader/>}><Cooptationsentnotification {...props}/><Footer/></React.Suspense></>} />
                  <Route exact path="/cooptationfollowup" name="One Cooptation Page" render={props =>  <><Header t = {this.props.i18n}/><React.Suspense fallback={<Loader/>}><Cooptationfollowup {...props}/><Footer/></React.Suspense></>} />
                  <Route exact path="/my-cooptations/:id" name="One Cooptation Page" render={props =>  <><Header t = {this.props.i18n}/><React.Suspense fallback={<Loader/>}><Cooptationfollowup {...props}/><Footer/></React.Suspense></>} />
                  {user?.profile === 'ADM' &&
                    <>
                      <Route exact path="/adm-cooptations" name="Administration List Page" render={props => <><Header t={this.props.i18n} /><React.Suspense fallback={<Loader/>}><ListCooptations {...props} /><Footer /></React.Suspense></>} />
                      <Route exact path="/adm-cooptations/:id" name="Administration Cooptation Page" render={props => <><Header t={this.props.i18n} /><React.Suspense fallback={<Loader/>}><DetailCooptation {...props} /><Footer /></React.Suspense></>} />

                      <Route exact path="/adm-duplications" name="Administration List duplication Page" render={props => <><Header t={this.props.i18n} /><React.Suspense fallback={<Loader/>}><ListDuplications {...props} /><Footer /></React.Suspense></>} />
                      <Route exact path="/adm-duplications/:ref" name="Administration Duplication Page" render={props => <><Header t={this.props.i18n} /><React.Suspense fallback={<Loader/>}><DetailDuplication {...props} /><Footer /></React.Suspense></>} />                  
                    </>
                  }
                  <Route  name="Page 404" render={props => <><Header t = {this.props.i18n}/><Page404 {...props}/><Footer/></>} />     
              </Switch> 
            </BlockUi>     
            </React.Suspense>
          </ScrollToTop>
        </HashRouter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const actionCreators = {
  clearAlerts: alertActions.clear
};

export default connect(mapStateToProps, actionCreators)(withTranslation() (App));
