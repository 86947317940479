// Sessions variable's name
export const TOKEN_USER     = "TOKEN_USER";
export const CURRENT_USER   = "CURRENT_USER";
export const CURRENT_LANG   = "CURRENT_LANG";
export const APPCONFIG = "APPCONFIG"; 
export const SCREEN_MODE = "SCREEN_MODE";

export const authActionsType = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',  
    LOGOUT: 'LOGOUT'
};

export const candidateActionsType = {
    CREATE_CANDIDATE: 'CREATE_CANDIDATE',
    UPDATE_CANDIDATE: 'UPDATE_CANDIDATE',
    LIST_CANDIDATES: 'LIST_CANDIDATES',
    LIST_TIMELINE: 'LIST_TIMELINE',
    READ_CV: 'READ_CV'
};

export const adminActionsType = {
    LIST_COOPTATIONS: 'LIST_COOPTATIONS',
    COOPTATION: 'COOPTATION',
    LIST_STATES: 'LIST_STATES',
    CREATED_STEP: 'CREATED_STEP',
    DELETED_STEP: 'DELETED_STEP',
    DELETED_COOPTATION: 'DELETED_COOPTATION',
    ADM_MENU_SELECTED: 'ADM_MENU_SELECTED',
    LIST_DUPLICATIONS: 'LIST_DUPLICATIONS',
    GET_DUPLICATION: 'GET_DUPLICATION',
    OWNER_CHANGED: 'OWNER_CHANGED',
    OWNER_UNREJECTED: 'OWNER_UNREJECTED'
};

export const alertActionsType = {
    SUCCESS: 'ALERT_SUCCESS',
    WARNING: 'ALERT_WARNING',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const loaderActionsType = {
    PENDINGMINI: 'PENDING_LOADER_MINI',
    PENDING: 'PENDING_LOADER',
    END: 'END_LOADER'
};

export const endpointsWithMiniLoader = [
    "/spa/duplications/all",
    "./spa/duplications/all",
    "/spa/cooptations/all",
    "./spa/cooptations/all",
    "/spa/states",
    "./spa/states"
]

export const endpointsWithoutLoader = [
    "/spa/states",
    "./spa/states"
]