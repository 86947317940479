import { adminService } from '../../services';
import { adminActionsType } from '../../constants';
import { alertActions } from '.';

// List of cooptations
const getCooptations = (page, size, sort, search) => {
    return dispatch => {
        return adminService.getCooptations(page, size, sort, search)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(cooptations) {
        return { type: adminActionsType.LIST_COOPTATIONS, cooptations } }
}

// Get cooptation
const getCooptation = (id) => {
    return dispatch => {
        return adminService.getCooptation(id)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(cooptation) {
        return { type: adminActionsType.COOPTATION, cooptation } }
}

// Get states
const getStates = () => {
    return dispatch => {
        return adminService.getAllStates()
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(states) {
        return { type: adminActionsType.LIST_STATES, states } }
}

// Delete step
const deleteStep = (cooptId, hisId) => {
    return dispatch => {
        return adminService.deleteStep(cooptId, hisId)
        .then(
            deleted => { 
                return dispatch(success(deleted));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(deleted) {
        return { type: adminActionsType.DELETED_STEP, deleted } }
}

// Create step
const createStep = (id, payload) => {
    return dispatch => {
        return adminService.createStep(id, payload)
        .then(
            created => { 
                return dispatch(success(created));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(created) {
        return { type: adminActionsType.CREATED_STEP, created } }
}

// Delete step
const deleteCooptation = (id) => {
    return dispatch => {
        return adminService.deleteCooptation(id)
        .then(
            deleted => { 
                return dispatch(success(deleted));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(deleted) {
        return { type: adminActionsType.DELETED_COOPTATION, deleted } }
}

// List of duplications
const getDuplications = (page, size, sort, search) => {
    return dispatch => {
        return adminService.getDuplications(page, size, sort, search)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(duplications) {
        return { type: adminActionsType.LIST_DUPLICATIONS, duplications } }
}

// Get one duplication
const getDuplication = (refCandidate) => {
    return dispatch => {
        return adminService.getDuplication(refCandidate)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(duplication) {
        return { type: adminActionsType.GET_DUPLICATION, duplication } }
}

// Get main cooptation
const getCooptationOwner = (canReference) => {
    return dispatch => {
        return adminService.getCooptationOwner(canReference)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(cooptation) {
        return { type: adminActionsType.COOPTATION, cooptation } }
}

// Change main cooptation
const changeCooptationOwner = (canId, data) => {
    return dispatch => {
        return adminService.changeCooptationOwner(canId, data)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(ownerChanged) {
        return { type: adminActionsType.OWNER_CHANGED, ownerChanged } }
}

// Unreject the rejected main owner
const unrejectMainOwner = (canId, data) => {
    return dispatch => {
        return adminService.unrejectMainOwner(canId, data)
        .then(
            response => { 
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(ownerUnrejected) {
        return { type: adminActionsType.OWNER_UNREJECTED, ownerUnrejected } }
}

export const adminActions = {
    getCooptations,
    getCooptation,
    getStates,
    createStep,
    deleteStep,
    deleteCooptation,
    getDuplications,
    getDuplication,
    getCooptationOwner,
    changeCooptationOwner,
    unrejectMainOwner
};