import React, { Component } from 'react';
import { Trans } from "react-i18next";
import './../../styles/loader.spinner.scss'; 

class Loader extends Component {
    render(){
      return (           
        <div className="divLoader" style={{left:this.props.message?'calc(50%)':'calc(50% -80px)'}}>
          {/* <svg className="svgLoader" viewBox="0 0 100 100" width="5em" height="5em">
            <path   stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#00c072" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
          </svg> */}
          <div className="loadingio-spinner-ellipsis-jjawpuv739m" style={{paddingLeft: 48}}><div className="ldio-hp4uicrw139">
            <div></div><div></div><div></div><div></div><div></div>
          </div></div>
          
          {this.props.message &&
            <div className="spinner-message"><Trans>{this.props.message}</Trans></div>
          }
        </div>
      );
    }
};

export default Loader;
