import axios from "axios";
import { TOKEN_USER, loaderActionsType, endpointsWithoutLoader } from "../constants"; 
import { history } from "../tools";
import { store } from '../redux/store';

const APIURL = process.env.PUBLIC_URL + "/spa";

//
axios.interceptors.request.use((config) => {
    let message = "";
    if(config.url === "/spa/Candidates" || config.url === "./spa/Candidates") message = "CREATE_COOPTATION";
    if(config.url === "/spa/Candidates/hireability" || config.url === "./spa/Candidates/hireability") message = "READING_CV";

    if (endpointsWithoutLoader.some((string) => config.url.includes(string))) {
        store.dispatch({ type: loaderActionsType.PENDINGMINI });
    } else {
        store.dispatch({ type: loaderActionsType.PENDING, message });
    }
    
    config.headers = getHeaders();
    return config;
}, error => {
    store.dispatch({ type: loaderActionsType.END });    
    return Promise.reject(error);
});

//
axios.interceptors.response.use(response => {
    store.dispatch({ type: loaderActionsType.END });    
    return response.data;
  }, error => {
    store.dispatch({ type: loaderActionsType.END });    
    if(error.response){
        if(error.response.status === "404") return Promise.reject("SERVER_UNREACHABLE");
        if(error.response.status === "401"){
            localStorage.clear();
            history.push("/login");
        }else
            return Promise.reject(error.response.data.message);
    }else{      
        return Promise.reject(error.toString());
    }
});

//
const get = (endpoint, filter = null) => {
    const url = `${APIURL}/` + endpoint;
    return axios.get(url);
}

//
const post = (endpoint, data) => {
    const url = `${APIURL}/` + endpoint;
    return axios.post(url, data);
}

//
const put = (endpoint, data) => {
    const url = `${APIURL}/` + endpoint;
    return axios.put(url, data);
}

//
const del = (endpoint) => {
    const url = `${APIURL}/${endpoint}`;
    return axios.delete(url);
}

//
const getHeaders = (action = null) => {
    let token = localStorage.getItem(TOKEN_USER);
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': token?'Bearer ' + token : 'No Auth',
        'headers': 'application/json'
    }
    return headers;
}

//
export const commonService = {
    get,
    post,
    put,
    del
};
