import { commonService } from '.';
//
function login(email = null, token = null) {
    const body = {
        "Email": email,
        "IDToken": token
    };
    return commonService.post('Auth', body);
}

//
export const userService = {
    login
};
