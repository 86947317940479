import { alertActionsType } from '../../constants';

export const alert = (state = {}, action) => {
  switch (action.type) {
    case alertActionsType.ERROR:
      return {
        type: 'danger',
        message: action.message
      };     
    case alertActionsType.CLEAR:
      return {};
    default:
      return state
  }
}