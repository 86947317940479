import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { alertActions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

export default function AlertDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  
  const alert = useSelector(state => state.alert);

  useEffect(() => {
    setOpen(true);
  }, [dispatch])

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClose = () => {
      dispatch(alertActions.clear());
      setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><Trans>ERROR_MODAL</Trans></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Trans>{alert.message}</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-md btn-success btn-login" onClick={handleClose}>
            <Trans>YES</Trans>
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
