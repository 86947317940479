import { combineReducers } from 'redux';
import { authentication } from './auth.reducer';
import { candidates } from './candidate.reducer';
import { loading } from './loader.reducer';
import { alert } from './alert.reducer';
import { administrations } from './admin.reducer';

const RootReducer = combineReducers({
    authentication,
    candidates,
    alert,
    loading,
    administrations
});

export default RootReducer;
