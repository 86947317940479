import { alertActionsType } from '../../constants';

const error = (message) => {
    return { type: alertActionsType.ERROR, message };
}

const clear = () => {
    return { type: alertActionsType.CLEAR };
}

export const alertActions = {
    error,
    clear
};