import { MsalAuthProvider, LoginType } from 'react-aad-msal';
const { REACT_APP_MFAAUTHORITY, REACT_APP_MFACLIENTID, REACT_APP_MFAPOSTLOGOUTREDIRECTURI, REACT_APP_MFAREDIRECTURI } = process.env;

// Msal Configurations
const config = {
  auth: {
    authority: REACT_APP_MFAAUTHORITY,
    clientId: REACT_APP_MFACLIENTID,
    postLogoutRedirectUri: REACT_APP_MFAPOSTLOGOUTREDIRECTURI,
    redirectUri: REACT_APP_MFAREDIRECTURI,
    validateAuthority: true,
    
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: ["openid"]
}
 
// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)