import { candidateActionsType } from '../../constants';

export function candidates (state = {}, action) {

  switch (action.type) {
    case candidateActionsType.CREATE_CANDIDATE:
      return {
        ...state,
        candidate: action.candidate
      };

    case candidateActionsType.LIST_CANDIDATES:
      return {
        ...state,
        candidates: action.candidates
      };

    case candidateActionsType.LIST_TIMELINE:
      return {
        ...state,
        timeline: action.timeline
      };

    case candidateActionsType.READ_CV:
      return {
        ...state,
        cv: action.cv
      };

    default:
      return state
  }
}

